exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capitulo-monterrey-js": () => import("./../../../src/pages/capituloMonterrey.js" /* webpackChunkName: "component---src-pages-capitulo-monterrey-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-que-es-js": () => import("./../../../src/pages/queEs.js" /* webpackChunkName: "component---src-pages-que-es-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienesSomos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */)
}

